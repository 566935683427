import { defineStore } from 'pinia';
import axios from '@/axios';
import { useToastStore } from './toast';

export const useAuthStore = defineStore('auth', {
  state: () => ({
    accessToken: localStorage.getItem('accessToken') || '',
    refreshToken: localStorage.getItem('refreshToken') || '',
    user: null,
    error: null,
  }),
  getters: {
    isAuthenticated: (state) => !!state.accessToken && !!state.user,
  },
  actions: {
    async login(username, password) {
      this.error = null;
      const toastStore = useToastStore();

      try {
        const response = await axios.post('/api/token/', { username, password }, {
          withCredentials: true,
        });

        if (response.status === 200) {
          this.accessToken = response.data.access;
          this.refreshToken = response.data.refresh;
          localStorage.setItem('accessToken', this.accessToken);
          localStorage.setItem('refreshToken', this.refreshToken);

          // Store tokens in cookies for cross-domain access
          this.setAuthCookies();

          await this.fetchUser();
          toastStore.showToast(3000, 'Login successful!', 'bg-secondary');
        } else {
          throw new Error('Login not successful');
        }
      } catch (error) {
        this.error = error.response?.data?.detail || error.message;
        toastStore.showToast(3000, `${this.error}`, 'bg-red-700');
        console.error('Error during login:', error);
      }
    },

    async register(username, email, password) {
      this.error = null;
      const toastStore = useToastStore();

      try {
        const response = await axios.post('/api/register/', { username, email, password }, {
          withCredentials: true,
        });

        if (response.status === 201) {
          toastStore.showToast(3000, 'Registration successful!', 'bg-secondary');
        } else {
          throw new Error('Registration not successful');
        }
      } catch (error) {
        this.error = error.response?.data?.detail || error.message;
        toastStore.showToast(3000, `${this.error}`, 'bg-red-700');
        console.error('Error during registration:', error);
      }
    },

    async fetchUser() {
      if (this.accessToken) {
        try {
          const response = await axios.get('/api/user/', {
            headers: {
              Authorization: `Bearer ${this.accessToken}`,
            },
            withCredentials: true,
          });
          this.user = response.data;
        } catch (error) {
          if (error.response?.status === 401) {
            await this.refreshTokenAndRetry(); // Try refreshing the token
          } else {
            this.error = error.response?.data?.detail || error.message;
            console.error('Error fetching user:', error);
          }
        }
      }
    },

    async refreshTokenAndRetry() {
      // Attempt to refresh the access token using the refresh token
      if (this.refreshToken) {
        try {
          const response = await axios.post('/api/token/refresh/', { refresh: this.refreshToken }, {
            withCredentials: true,
          });

          if (response.status === 200) {
            this.accessToken = response.data.access;
            localStorage.setItem('accessToken', this.accessToken);
            this.setAuthCookies(); // Update the cookie with the new access token
            await this.fetchUser(); // Retry the fetchUser call
          } else {
            this.logout(); // Logout on refresh token failure
          }
        } catch (error) {
          this.logout();
          console.error('Error refreshing token:', error);
        }
      } else {
        this.logout();
      }
    },

    logout() {
      this.accessToken = '';
      this.refreshToken = '';
      this.user = null;
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      this.clearAuthCookies();

      const toastStore = useToastStore();
      toastStore.showToast(3000, 'Logged out successfully!', 'bg-secondary');
    },

    setAuthCookies() {
      // Set cookies for access and refresh tokens across subdomains
      document.cookie = `accessToken=${this.accessToken}; domain=.unveil-africa.com; path=/; SameSite=None; Secure`;
      document.cookie = `refreshToken=${this.refreshToken}; domain=.unveil-africa.com; path=/; SameSite=None; Secure`;
    },

    clearAuthCookies() {
      // Clear cookies on logout
      document.cookie = 'accessToken=; Max-Age=0; domain=.unveil-africa.com; path=/; SameSite=None; Secure';
      document.cookie = 'refreshToken=; Max-Age=0; domain=.unveil-africa.com; path=/; SameSite=None; Secure';
    },
  },
  persist: {
    storage: localStorage,
  },
});
