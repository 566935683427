<template>
    <div class="relative my-auto">
      <!-- Current theme button -->
      <button @click="toggleDropdown" class="relative">
        <svg v-if="currentMode === 'light'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M21.752 15.002A9.72 9.72 0 0 1 18 15.75c-5.385 0-9.75-4.365-9.75-9.75 0-1.33.266-2.597.748-3.752A9.753 9.753 0 0 0 3 11.25C3 16.635 7.365 21 12.75 21a9.753 9.753 0 0 0 9.002-5.998Z" />
        </svg>
        <svg v-if="currentMode === 'dark'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M12 3v2.25m6.364.386-1.591 1.591M21 12h-2.25m-.386 6.364-1.591-1.591M12 18.75V21m-4.773-4.227-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0Z" />
        </svg>
        <svg v-if="currentMode === 'system'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 0 0 1 1.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.559.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.894.149c-.424.07-.764.383-.929.78-.165.398-.143.854.107 1.204l.527.738c.32.447.269 1.06-.12 1.45l-.774.773a1.125 1.125 0 0 1-1.449.12l-.738-.527c-.35-.25-.806-.272-1.203-.107-.398.165-.71.505-.781.929l-.149.894c-.09.542-.56.94-1.11.94h-1.094c-.55 0-1.019-.398-1.11-.94l-.148-.894c-.071-.424-.384-.764-.781-.93-.398-.164-.854-.142-1.204.108l-.738.527c-.447.32-1.06.269-1.45-.12l-.773-.774a1.125 1.125 0 0 1-.12-1.45l.527-.737c.25-.35.272-.806.108-1.204-.165-.397-.506-.71-.93-.78l-.894-.15c-.542-.09-.94-.56-.94-1.109v-1.094c0-.55.398-1.02.94-1.11l.894-.149c.424-.07.765-.383.93-.78.165-.398.143-.854-.108-1.204l-.526-.738a1.125 1.125 0 0 1 .12-1.45l.773-.773a1.125 1.125 0 0 1 1.45-.12l.737.527c.35.25.807.272 1.204.107.397-.165.71-.505.78-.929l.15-.894Z" />
          <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
        </svg>
      </button>
  
      <transition name="fade">
        <div v-if="dropdownOpen" @click.self="closeModel" class="dropdown absolute right-0 mt-2 w-48 bg-white border border-gray-300 shadow-lg rounded">
          <div class="flex flex-col">
            <button @click="setMode('light')" class="px-4 py-2 text-gray-700 hover:bg-gray-100 text-left w-full">
              <span>Light Mode</span>
              <svg v-if="currentMode === 'light'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 inline-block ml-2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 3v2.25m6.364.386-1.591 1.591M21 12h-2.25m-.386 6.364-1.591-1.591M12 18.75V21m-4.773-4.227-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0Z" />
              </svg>
            </button>
            <button @click="setMode('dark')" class="px-4 py-2 text-gray-700 hover:bg-gray-100 text-left w-full">
              <span>Dark Mode</span>
              <svg v-if="currentMode === 'dark'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 inline-block ml-2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 3v2.25m6.364.386-1.591 1.591M21 12h-2.25m-.386 6.364-1.591-1.591M12 18.75V21m-4.773-4.227-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0Z" />
              </svg>
            </button>
            <button @click="setMode('system')" class="px-4 py-2 text-gray-700 hover:bg-gray-100 text-left w-full">
              <span>System Mode</span>
              <svg v-if="currentMode === 'system'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 inline-block ml-2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 3v2.25m6.364.386-1.591 1.591M21 12h-2.25m-.386 6.364-1.591-1.591M12 18.75V21m-4.773-4.227-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0Z" />
              </svg>
            </button>
          </div>
        </div>
      </transition>
    </div>
  </template>
  
  <script setup>
  import { ref, watchEffect, onMounted, onUnmounted } from 'vue';
  import { useToastStore } from '@/store/toast';
  
  const modes = ['light', 'dark', 'system'];
  const storedMode = localStorage.getItem('theme') || 'light';
  const currentMode = ref(storedMode);
  const dropdownOpen = ref(false);
  const toastStore = useToastStore();
  
  const toggleDropdown = () => {
    dropdownOpen.value = !dropdownOpen.value;
  };
  
  const setMode = (mode) => {
    currentMode.value = mode;
    toastStore.showToast(3000,`${mode} mode is activated`, 'bg-secondary');
    dropdownOpen.value = false;
  };
  
  // Click outside handler
  const handleClickOutside = (event) => {
    const dropdownElement = document.querySelector('.dropdown');
    if (dropdownElement && !dropdownElement.contains(event.target) && !event.target.closest('.relative button')) {
      dropdownOpen.value = false;
    }
  };
  
  // Setup and cleanup click listener
  onMounted(() => {
    window.addEventListener('click', handleClickOutside);
  });
  
  onUnmounted(() => {
    window.removeEventListener('click', handleClickOutside);
  });
  
  watchEffect(() => {
    document.documentElement.classList.remove(...modes);
    if (currentMode.value !== 'system') {
      document.documentElement.classList.add(currentMode.value);
      localStorage.setItem('theme', currentMode.value);
    } else {
      localStorage.removeItem('theme');
    }
  });
  </script>
  
  
  <style scoped>
  button {
    cursor: pointer;
    transition: opacity 0.3s;
  }
  
  button:hover {
    opacity: 0.75;
  }
  
  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.3s;
  }
  
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
  
  .tooltip {
    opacity: 0;
    position: absolute;
    background: rgba(0, 0, 0, 0.75);
    color: #fff;
    padding: 0.5rem;
    border-radius: 0.25rem;
    transform: translateY(-0.5rem);
    white-space: nowrap;
    transition: opacity 0.3s;
  }
  </style>
  