<template>
    <div v-if="showConsentModal" @click.self="closeModal" class="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center z-50">
      <div class="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 w-11/12 md:w-2/3 lg:w-1/2">
        <div class="flex justify-between items-center pb-4 border-b dark:border-gray-700">
          <img src="../assets/nice1.png" alt="Unveil Africa logo" class="h-8">
          <button @click="closeModal" class="text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300">&times;</button>
        </div>
        <div class="pt-4">
          <h2 class="text-xl font-semibold text-gray-800 dark:text-gray-100 mb-2">Are you okay with cookies?</h2>
          <p class="text-gray-600 dark:text-gray-400 mb-4">We use cookies to make our website work, analyze our traffic, and keep you safe.</p>
          <div class="flex justify-between items-center">
            <button @click="toggleSettings" class="bg-gray-300 dark:bg-gray-600 text-gray-800 dark:text-gray-200 px-4 py-2 rounded-lg hover:bg-gray-400 dark:hover:bg-gray-500 mr-4">Cookie Settings</button>
            <button @click="acceptConsent" class="bg-secondary dark:bg-secondary-dark text-white px-4 py-2 rounded-lg hover:bg-secondary-dark">Accept</button>
          </div>
          <p class="text-gray-500 dark:text-gray-400 text-xs mt-4">Rejecting all cookies may limit certain features.</p>
          <div v-if="showSettings" class="mt-4 border-t dark:border-gray-700 pt-4">
            <div class="flex items-center mb-2">
              <input type="checkbox" v-model="cookiesNecessary" id="necessary" class="mr-2">
              <label for="necessary" class="text-gray-800 dark:text-gray-100">Necessary Cookies</label>
            </div>
            <div class="flex items-center mb-2">
              <input type="checkbox" v-model="cookiesAnalytics" id="analytics" class="mr-2">
              <label for="analytics" class="text-gray-800 dark:text-gray-100">Analytics Cookies</label>
            </div>
            <div class="flex items-center mb-2">
              <input type="checkbox" v-model="cookiesAds" id="ads" class="mr-2">
              <label for="ads" class="text-gray-800 dark:text-gray-100">Advertising Cookies</label>
            </div>
            <button @click="saveSettings" class="bg-secondary dark:bg-secondary-dark text-white px-4 py-2 rounded-lg hover:bg-secondary-dark mt-2">Save Settings</button>
            <button @click="declineAll" class="bg-gray-500 dark:bg-gray-700 text-white px-4 py-2 rounded-lg hover:bg-gray-600 dark:hover:bg-gray-600 mt-2">Decline All</button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        showConsentModal: false,
        showSettings: false,
        cookiesNecessary: true,
        cookiesAnalytics: false,
        cookiesAds: false
      };
    },
    mounted() {
      this.checkConsent();
    },
    beforeUnmount() {
      window.removeEventListener('beforeunload', this.clearSessionConsent);
    },
    methods: {
      checkConsent() {
        const consent = localStorage.getItem('userConsent');
        const sessionConsent = sessionStorage.getItem('userConsent');
        if (!consent && !sessionConsent) {
          this.showConsentModal = true;
        }
      },
      acceptConsent() {
        localStorage.setItem('userConsent', 'accepted');
        window.gtag('consent', 'update', {
          'ad_storage': 'granted',
          'analytics_storage': 'granted'
        });
        this.showConsentModal = false;
      },
      toggleSettings() {
        this.showSettings = !this.showSettings;
      },
      saveSettings() {
        const consentSettings = {
          necessary: this.cookiesNecessary,
          analytics: this.cookiesAnalytics,
          ads: this.cookiesAds
        };
        localStorage.setItem('userConsentSettings', JSON.stringify(consentSettings));
        this.showConsentModal = false;
      },
      declineAll() {
        sessionStorage.setItem('userConsent', 'declined');
        this.showConsentModal = false;
      },
      closeModal() {
        this.showConsentModal = false;
      },
    }
  };
  </script>
  
  <style scoped>
  /* Add custom styles if necessary */
  </style>
  