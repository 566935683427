<template>
    <div id="marketing-banner" tabindex="-1" class="flex px-20 flex-col md:flex-row justify-between w-full p-4 mx-auto bg-white border-b border-gray-100 shadow-sm lg:max-w-full dark:bg-dark dark:border-dark-light">
      <div class="flex flex-col items-start mb-3 me-4 md:items-center md:flex-row md:mb-0">
        <a href="/" class="flex items-center mb-2 border-gray-200 md:pe-4 md:me-4 md:border-e md:mb-0 dark:border-gray-600">
          <img src="../assets/nice1.png" class="h-6 me-2" alt="Unveil Africa Logo">
          <span class="engraved self-center text-2xl font-handwriting font-semibold text-secondary-light dark:text-secondary-dark lightspace-nowrap">
            <span class="text-primary">Unveil </span>Africa
          </span>
        </a>
        <p class="flex items-center text-sm font-normal text-gray-500 dark:text-gray-400">{{ currentService }}</p>
      </div>
      <div class="flex items-center flex-shrink-0">
        <a href="/register" class="px-5 py-2 me-2 text-xs font-medium text-white bg-secondary rounded-lg hover:bg-secondary-dark focus:ring-4 focus:ring-green-300 dark:bg-secondary-light dark:hover:bg-secondary focus:outline-none dark:focus:ring-green-800">Get Started</a>
        <button data-dismiss-target="#marketing-banner" type="button" class="flex-shrink-0 inline-flex justify-center w-7 h-7 items-center text-gray-400 hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 dark:hover:bg-gray-600 dark:hover:text-white">
          <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
          </svg>
          <span class="sr-only">Close banner</span>
        </button>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        services: [
          "Explore our customized travel itineraries for an unforgettable African adventure!",
          "Join our virtual cultural experiences and learn about Africa's diverse cultures.",
          "Discover unique African art and crafts through our exclusive online marketplace.",
          "Plan your dream African vacation with our expert travel consultants.",
          "Stay updated with the latest events and festivals happening across Africa.",
          "Flight Booking: Integration with flight search engines",
          "Hotel Booking: Integration with hotel booking platforms",
          "Tour Packages: Curated tour packages with descriptions and prices",
          "Car Rental Services: Integration with car rental agencies",
          "Activity and Excursion Booking: Booking options for local activities and excursions",
          "Business Services: Options for conference room bookings and co-working spaces",
          "Permanent Residence and Relocation Assistance: Information on relocation services",
          "Investment Opportunities: Resources and insights for investors",
          "Travel Insurance: Options for purchasing travel insurance",
          "Local Guides and Translators: Services to connect with local guides and translators",
        ],
        interval: 1 * 60 * 60 * 1000
      };
    },
    computed: {
      currentService() {
        const now = new Date();
        const serviceIndex = Math.floor((now.getTime() % this.interval) / this.interval * this.services.length);
        return this.services[serviceIndex];
      }
    },
    created() {
      setInterval(() => {
        this.$forceUpdate();
      }, this.interval);
    }
  };
  </script>
  
  <style scoped>
  /* Add any custom styles here */
  </style>
  