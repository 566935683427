<template>
    <div :class="toast.classes" v-if="toast.isVisible" class="transition ease-in-out delay-500 duration-500 px-6 py-4 fixed top-full left-1/2 transform -translate-x-1/2 w-auto max-w-auto text-black text-center rounded-xl shadow-xl">
        {{ toast.message }}
    </div>
</template>

<script>
import { useToastStore } from '@/store/toast';

export default {
    setup() {
        const toast = useToastStore();
        return {
            toast
        }
    }
}
</script>

<style scoped>
.fixed {
    z-index: 1000; /* Ensure the toast appears above other content */
}
</style>
