// src/composables/useFileHelpers.js
export function useFileHelpers() {
  const time = (value) => {
    const date = new Date(value);
    const now = new Date();
    const diff = Math.abs(now - date) / 1000;
    if (diff < 60) {
      return `${Math.floor(diff)} seconds ago`;
    } else if (diff < 3600) {
      return `${Math.floor(diff / 60)} minutes ago`;
    } else if (diff < 86400) {
      return `${Math.floor(diff / 3600)} hours ago`;
    } else {
      return `${Math.floor(diff / 86400)} days ago`;
    }
  };

  const isImage = (filename) => {
    const ext = filename.split('.').pop().toLowerCase();
    return ['jpg', 'jpeg', 'png', 'gif', 'svg'].includes(ext);
  };

  const isVideo = (filename) => {
    const ext = filename.split('.').pop().toLowerCase();
    return ['mp4', 'webm', 'ogg'].includes(ext);
  };

  const isAudio = (filename) => {
    const ext = filename.split('.').pop().toLowerCase();
    return ['mp3', 'wav', 'ogg'].includes(ext);
  };

  const formatDate = (value) => {
    const date = new Date(value);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}${month}${year}`;
  };

  return {
    time,
    isImage,
    isVideo,
    isAudio,
    formatDate,
  };
}
