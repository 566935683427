<template>
  <div>
    <header>
      <nav
      data-collapse-toggle="navbar-sticky"
      :class="{
        'bg-light text-secondary-light dark:bg-dark dark:border-gray-950 border-gray-200': isScrolled,
        'bg-transparent text-secondary-light border-none': !isScrolled
      }"
      class="fixed w-full z-20 top-0 start-0 border-b"
    >
    <MarketingBanner />

      <div class="max-w-screen-3xl flex flex-wrap items-center justify-around mx-auto py-8">
        <router-link to="/" class="flex items-center space-x-3 rtl:space-x-reverse">
          <span class="engraved self-center text-3xl font-handwriting font-semibold text-secondary-light dark:text-secondary-dark lightspace-nowrap">
            <span class="text-primary">Unveil </span>Africa
          </span>
        </router-link>
        <div class="flex md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse">
          <div>
            <div v-if="authStore.isAuthenticated && authStore.user" class="flex items-center">
              <div class="bg-primary text-white rounded-full w-8 h-8 flex items-center justify-center mr-2">
                {{ authStore.user.username.charAt(0).toUpperCase() }}
              </div>
              <span>Welcome {{ authStore.user.username }}</span>
              <button
                @click="logout"
                data-tooltip-target="tooltip-right"
                data-tooltip-placement="right"
                type="button"
                class="ms-3 mb-2 md:mb-0 text-white bg-secondary-light hover:bg-secondary-dark focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-secondary-dark dark:hover:bg-secondary-light dark:focus:ring-secondary"
              >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M8.25 9V5.25A2.25 2.25 0 0 1 10.5 3h6a2.25 2.25 0 0 1 2.25 2.25v13.5A2.25 2.25 0 0 1 16.5 21h-6a2.25 2.25 0 0 1-2.25-2.25V15M12 9l3 3m0 0-3 3m3-3H2.25"
                  />
                </svg>
              </button>
              <div
                id="tooltip-right"
                role="tooltip"
                class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700"
              >
                Logout
                <div class="tooltip-arrow" data-popper-arrow></div>
              </div>
            </div>
            <div v-else class="flex gap-3">
              <router-link to="/login">
                <button
                  type="button"
                  class="hidden md:block bg-secondary hover:bg-primary focus:ring-4 focus:outline-none focus:ring-purple-300 font-medium rounded-full text-lg px-8 py-2 text-center font-handwriting"
                >
                  Sign In
                </button>
              </router-link>
              <ModeSwitcher />
            </div>
          </div>
          <button
            @click="isScrolled = true"
            data-collapse-toggle="navbar-sticky"
            type="button"
            class="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
            aria-controls="navbar-sticky"
            aria-expanded="false"
          >
            <span class="sr-only">Open main menu</span>
            <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15" />
            </svg>
          </button>
        </div>
        <div class="items-center justify-between hidden w-full md:flex md:w-auto md:order-1" id="navbar-sticky">
          <ul class="flex flex-col p-4 md:p-0 mt-4 font-medium border border-gray-100 rounded-lg md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0">
            <li>
              <router-link to="/" class="block py-2 px-3 bg-secondary rounded md:bg-transparent md:p-0" aria-current="page">Home</router-link>
            </li>
            <li>
              <router-link to="/about" class="block py-2 px-3 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-secondary md:p-0">About Us</router-link>
            </li>
            <li>
              <router-link to="/events" class="block py-2 px-3 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-secondary md:p-0">Events</router-link>
            </li>
            <li>
              <router-link to="/explore" class="block py-2 px-3 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-secondary md:p-0">Explore Africa</router-link>
            </li>
            <li>
              <router-link to="/web-app" class="block py-2 px-3 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-secondary md:p-0">Web App</router-link>
            </li>
            <li>
              <router-link to="/blog" class="block py-2 px-3 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-secondary md:p-0">Blog</router-link>
            </li>
            <li>
              <router-link to="/contact" class="block py-2 px-3 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-secondary md:p-0">Contact Us</router-link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    </header>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import MarketingBanner from './MarketingBanner.vue';
import { useAuthStore } from '../store/auth';
import { useRouter } from 'vue-router';
import ModeSwitcher from './ModeSwitcher.vue';

const isScrolled = ref(false);
const authStore = useAuthStore();
const router = useRouter();

const logout = () => {
  authStore.logout();
  router.push('/');
};

onMounted(() => {
  window.addEventListener('scroll', () => {
    isScrolled.value = window.scrollY > 20;
  });
});
</script>

<style scoped>
.engraved {
  text-shadow: 1px 1px 0 #ffffff, -1px -1px 0 #ffffff, 1px -1px 0 #ffffff, -1px 1px 0 #ffffff, 1px 1px 2px rgba(0, 0, 0, 0.2), 2px 2px 6px rgba(0, 0, 0, 0.2), 3px 3px 9px rgba(0, 0, 0, 0.2);
}
</style>
