<template>
    <NavBar />
    <ConsentModal />
    <main>
        <router-view/>
    <button id="back-to-top" title="Back to Top">↑</button>
    <ToastCom />
    </main>
    <!-- <WhatsAppIcon @toggle-chat="toggleChat"/> -->
    <FooterCom />
  </template>
  
  <script setup>
  import { onMounted } from 'vue';
  import NavBar from '@/components/NavBar.vue';
  import FooterCom from '@/components/FooterCom.vue';
  import ToastCom from '@/components/ToastCom.vue';
  import ConsentModal from './components/ConsentModel.vue';
  
  // Import only necessary Flowbite components
  import { initDropdowns, initModals, initAccordions } from 'flowbite';
  
  
  onMounted(() => {
    // Initialize only the needed Flowbite components
    initAccordions();
    initDropdowns();
    initModals();
  
    let backToTopButton = document.getElementById("back-to-top");
  
    // Show button based on scroll position
    window.onscroll = function() {
      scrollFunction();
    };
  
    function scrollFunction() {
      if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
        backToTopButton.style.display = "block";
      } else {
        backToTopButton.style.display = "none";
      }
    }
  
    // Smooth scroll to top on button click
    backToTopButton.addEventListener('click', function() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    });
  });
  </script>
  
  <style>
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #006400;
    margin: 0;
    padding: 0;
    font-family: 'Poppins', serif;
  }
  
  nav a.router-link-exact-active {
    color: #FFD700;
  }
  
  .font-heading {
    font-family: 'Montserrat', sans-serif;
  }
  
  .slider-btn.active {
    background-color: #fbbf24; /* yellow-500 */
  }
  
  .slider-btn.inactive {
    background-color: #6b7280; /* gray-500 */
  }
  
  .date-ribbon {
    position: absolute;
    top: 16px;
    left: -32px;
    background: #1E3A8A;
    color: #fff;
    padding: 8px 16px;
    transform: rotate(-45deg);
    transform-origin: top left;
    text-align: center;
    width: 100px;
    font-size: 12px;
  }
  
  .icons li {
    list-style-type: none;
    background: #006400;
    color: #fff;
    width: 40px;
    height: 40px;
    margin: 0 0.5rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
  }
  
  .icons li:hover {
    background: #edffec;
    color: #000;
  }
  
  #back-to-top {
    display: none;
    position: fixed;
    height: 50px;
    bottom: 50%;
    right: 30px;
    z-index: 99;
    font-size: 18px;
    background-color: #FFD700;
    color: white;
    border: none;
    border-radius: 4px; 
    padding: 10px 15px;
    cursor: pointer;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
    transition: opacity 0.3s;
  }
  
  #back-to-top:hover {
    background-color: #006400; /* Darker green */
  }
  
  .font-handwriting {
    font-family: "Great Vibes", cursive;
    font-weight: 400;
    font-style: normal;
  }
  </style>
  